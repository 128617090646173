import React from 'react'
import { graphql } from 'gatsby'
import { gsap, TimelineMax } from 'gsap'
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin'
import ReactFullpage from '@fullpage/react-fullpage'
import get from 'lodash/get'
import { motion, AnimatePresence } from 'framer-motion'

// site layout
import SEO from '../components/scaffolding/seo'
import Module from '../components/scaffolding/module'
import Header from '../components/header'
import Footer from '../components/footer'
import CaseStudyPopup from '../components/modules/caseStudyPopup'

if (typeof window !== `undefined`) {
  gsap.install(window)
  gsap.registerPlugin(MorphSVGPlugin)
  gsap.core.globals('MorphSVGPlugin', MorphSVGPlugin)
}

const morphMap = [
  {
    morphEnd: '#_blank',
    fill: '#fff',
    opacity: 0
  },
  {
    morphEnd: '#hero',
    fill: '#EDFF00',
    opacity: 1
  },
  {
    morphEnd: '#one',
    fill: '#00ba99',
    opacity: 1
  },
  {
    morphEnd: '#two',
    fill: '#3336DB',
    opacity: 1
  },
  {
    morphEnd: '#three',
    fill: '#529EBF',
    opacity: 1
  },
  {
    morphEnd: '#_blank',
    fill: '#fff',
    opacity: 1
  },
  {
    morphEnd: '#four',
    fill: '#219E45',
    opacity: 1
  },
  {
    morphEnd: '#five',
    fill: '#FA7500',
    opacity: 1
  },
  {
    morphEnd: '#six',
    fill: '#ACE0BE',
    opacity: 1
  },
  {
    morphEnd: '#seven',
    fill: '#8C17E0',
    opacity: 1
  },
  {
    morphEnd: '#eight',
    fill: '#059EE1',
    opacity: 1
  }
]

class indexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0,
      paused: false,
      popupOpen: false,
      popupDetail: null,
      mobile: null
    }
    this._setPopup = this._setPopup.bind(this)
    this._closePopup = this._closePopup.bind(this)
    this._jumpToSlide = this._jumpToSlide.bind(this)
    this.tl = new TimelineMax()
  }

  componentDidMount() {
    let hash = this.props.location.hash
    hash === '#case-studies' && this._jumpToSlide(2)

    if (typeof document !== `undefined`) {
      this.setState({ mobile: document.documentElement.clientWidth <= 1024 })
    }
  }

  _setPopup(e) {
    e && this.setState({ popupDetail: e, popupOpen: true })
  }
  _closePopup(e) {
    this.setState({ popupOpen: false })
    setTimeout(() => {
      this._jumpToSlide(this.state.index + 1)
    }, 150)
  }
  _jumpToSlide(idx) {
    fullpage_api.moveTo(idx)
  }
  _handleChange(origin, destination, direction) {
    let { index } = destination
    let duration = 1
    this.setState({ index: index })
    if (index < morphMap.length) {
      this.tl.to('#_blank', duration, {
        morphSVG: morphMap[index].morphEnd,
        fill: morphMap[index].fill,
        fillOpacity: morphMap[index].opacity
      })
    }
  }

  render() {
    const { index, popupOpen, popupDetail, mobile } = this.state

    const homeBg = (
      <svg fill="none" viewBox={mobile ? '0 0 300 900' : '0 0 1 800'}>
        <path id="_blank" d="m0 0v800h599l-199.662-800z" fill="#ffffff" />
        <rect id="hero" width="800" height="800" fill="#EDFF00" />
        <path
          id="one"
          d="M599 800L599 0L6.99382e-05 -5.23662e-05L199.662 800L599 800Z"
          fill="#00ba99"
        />
        <path id="two" d="M0 0L800 0V394.878L0 523V0Z" fill="#3336DB" />
        <path id="three" d="M0 0V800L800 0H0Z" fill="#529EBF" />
        <path
          id="four"
          d="M599 800L599 0L0 0L199.662 800H599Z"
          fill="#219E45"
        />
        <path id="five" d="M0 800H800L0 0L0 800Z" fill="#FA7500" />
        <path id="six" d="M0 0V800L800 600V0H0Z" fill="#ACE0BE" />
        <path id="seven" d="M399 0L0 800H399V0Z" fill="#8C17E0" />
        <path id="eight" d="M800 0L0 0L200 800L800 800L800 0Z" fill="#059EE1" />
      </svg>
    )
    const page = this.props.data.page && get(this, 'props.data.page')

    return (
      <>
        <script
          type="text/javascript"
          src="https://confirmsubscription.com/h/y/C5E9E69124B95464/551C608FF955619D/popup.js"
        ></script>
        <div className="bgImg">{homeBg}</div>
        <SEO
          description={page && page.seo ? page.seo.description : ''}
          shareGraphicURL={
            page && page.seo && page.seo.share
              ? page.seo.share.asset.fixed.src
              : null
          }
        />
        <Header
          active={'/'}
          location={this.props.location}
          mobile={mobile}
          jump={this._jumpToSlide}
          expanded={!mobile && index === 0}
        />

        <main role="main" id="content">
          <AnimatePresence exitBeforeEnter>
            {popupOpen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.25 }}
              >
                <CaseStudyPopup
                  mobile={mobile}
                  module={popupDetail}
                  isOpen={popupOpen}
                  close={this._closePopup}
                />
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence exitBeforeEnter>
            {!popupOpen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0 }}
              >
                <ReactFullpage
                  scrollingSpeed={1000}
                  licenseKey={process.env.GATSBY_FULLPAGE_KEY}
                  onLeave={this._handleChange.bind(this)}
                  easing={'easeInOutCubic'}
                  render={fullPageApi => {
                    return (
                      <ReactFullpage.Wrapper>
                        {page.content.map((module, key) => {
                          return (
                            <Module
                              key={key}
                              page={page}
                              mobile={mobile}
                              module={module}
                              popupOpen={popupOpen}
                              callback={this._setPopup}
                              jump={this._jumpToSlide}
                              index={this.state.index}
                            />
                          )
                        })}
                        <Footer homePage={true} jump={this._jumpToSlide} />
                      </ReactFullpage.Wrapper>
                    )
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </main>
      </>
    )
  }
}

export default indexPage

export const homePageQuery = graphql`
  {
    page: sanityHome {
      _rawContent(resolveReferences: { maxDepth: 10 })
      content {
        ... on SanityCaseStudyHero {
          _key
          _type
          ctaLink
          ctaTitle
          subtitle
          title
        }
        ... on SanityCaseStudyGap {
          _key
          _type
          ctaLink
          ctaTitle
          title
        }
        ... on SanitySoftFooter {
          _key
          _type
          title
        }
        ... on SanityCaseStudies {
          _key
          _type
          company
          color
          name
          quote
          title
          subtitle
          text
          result
          press {
            publisher
            title
            url
          }
          image {
            asset {
              fluid {
                src
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityHeroVideo {
          _key
          _type
          title
          video
          logo {
            asset {
              fluid {
                src
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }

      seo {
        description
        share {
          asset {
            fixed(width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
  }
`
